import { module } from 'modujs';

export default class extends module {
    constructor(m) {
        super(m);
    }

    init() {
      
    }

    count(data) {
      var el = data.obj.el;
      $(el).prop('Counter',0).animate({
        Counter: $(el).text(),
      }, {
        duration: 4000,
        easing: 'swing',
        step: function (now) {
          $(el).text(Math.ceil(now).toLocaleString('cs-CZ'));
        },
      });
    }

}
